.btn {
    text-decoration: none;
    border: 1px solid var(--secundary);
    color: var(--orangeColor);
    transition: all 250ms ease;
}

.btn.px16 {
    font-size: 16px;
    padding: 16px 32px;
    font-weight: 500;
}

.btn.px14 {
    font-size: 14px;
    padding: 10px 28px;
    font-weight: 500;
}

.btn.roundCorners {
    border-radius: 50px;
}

.btn.solid {
    background-color: var(--secundary);
}

.btn.solid:hover {
    background-color: transparent;
    color:  var(--orangeColor);
}

.btn.trans {
    background-color: transparent;
}

.btn.trans:hover {
    background-color: var(--secundary);
    color: var(--orangeColor);
}

@media only screen and (max-width: 420px) {
    .btn {
        display: block;
        width: 100%;
    }
}