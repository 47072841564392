#calender {
    max-width: 1600px;
    height: fit-content;
    margin: 70px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
}

.calenderContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.calenderHeroContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 1.2rem;
}

.calenderHeroImageContainer {
    width: calc((100% / 2));
    position: relative;
}

.calenderHeroImageContainer>img {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 10;
}

.calenderHeroImageContainer::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--secundary);
    bottom: -15px;
    left: -15px;
}

.calenderHeroTextContainer {
    width: calc((100% / 2) - 100px);
}

.calenderHeroHeading {
    color: var(--secundary);
    font-weight: 500;
    font-size: 42px;
    margin-bottom: 30px;
}

.calenderHeroText {
    color: var(--secundary);
    line-height: 1.3;
    margin-bottom: 30px;
}









.calenderContentContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 1.2rem;
    margin-top: 150px;
    margin-bottom: 100px;
}

.calenderEventContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}

.calenderEventContainer:nth-child(even) {
    flex-direction: row-reverse;
}

.calenderEventContainer:nth-child(even) .calenderEventTextContainer {
    padding-right: 100px;
    padding-left: 0px;
}

.calenderEventImageContainer {
    width: calc((100% / 2));
    height: 500px;
    position: relative;
}

.calenderEventImageContainer>img, .calenderEventImageContainer>video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 10;
}

.calenderEventImageContainer::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--secundary);
    bottom: -15px;
    left: -15px;
}

.calenderEventTextContainer {
    width: calc(100% / 2);
    padding-left: 100px;
}

.calenderEventHeading {
    color: var(--orangeColor);
    font-size: 36px;
    margin-bottom: 20px;
}

.calenderEventTimePlace {
    color: var(--orangeColor70opacity);
    font-size: 16px;
    margin-bottom: 20px;
}

.calenderEventText {
    color: var(--secundary);
    line-height: 1.3;
    margin-bottom: 40px;
}

.btn.calenderEventProgramBtn {
    font-size: 16px;
    padding: 16px 32px;
    font-weight: 500;
    text-align: center;
}

@media only screen and (max-width: 1200px) {
    .calenderEventTextContainer {
        padding-left: 50px;
    }
    .calenderEventContainer:nth-child(even) .calenderEventTextContainer {
        padding-right: 50px;
    }
}

@media only screen and (max-width: 1024px) {
    .calenderEventImageContainer {
        height: 400px;
    }
    .calenderEventHeading {
        font-size: 32px;
    }
    .calenderEventTimePlace, .calenderEventText {
        font-size: 14px;
    }
    .btn.calenderEventProgramBtn {
        font-size: 14px;
        padding: 10px 28px;
        font-weight: 500;
    }
}

@media only screen and (max-width: 800px) {
    .calenderEventContainer {
        flex-direction: column;
        margin-bottom: 150px;
    }
    .calenderEventContainer:nth-child(even) {
        flex-direction: column;
    }
    .calenderEventImageContainer {
        width: 95%;
        margin-bottom: 50px;
    }
    .calenderEventTextContainer {
        width: 100%;
        padding: 0px !important;
    }
}

@media only screen and (max-width: 450px) {
    .calenderEventImageContainer {
        height: 300px;
    }
    .calenderEventHeading {
        font-size: 24px;
    }
}

@media only screen and (max-width: 350px) {
    .calenderEventImageContainer {
        height: 250px;
    }
}










@media only screen and (max-width: 1024px) {
    #calender {
        height: fit-content;
    }
    .calenderHeroContainer {
        flex-direction: column;
    }
    .calenderHeroImageContainer {
        width: 90%;
        margin-bottom: 50px;
    }
    .calenderHeroTextContainer {
        width: 90%;
    }
}

@media only screen and (max-width: 600px) {
    .calenderHeroHeading {
        font-size: 29px;
    }
    .calenderHeroText {
        font-size: 14px;
    }
    .calenderHeroImageContainer {
        width: 95%;
        margin-bottom: 50px;
    }
    .calenderHeroTextContainer {
        width: 100%;
    }
}