#signup {
    max-width: 900px;
    height: fit-content;
    margin: 0 auto;
    padding: 200px 0 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signupContainer {
    width: 100%;
    height: 100%;
    padding: 0 1.2rem;
}

.signupHeading {
    text-align: center;
    font-size: 39px;
    color: var(--orangeColor);
    margin-bottom: 30px;
}

.signupFormWrapper {
    background-color: var(--white);
    border-radius: 10px;
    padding: 75px;
    box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
}

.signupIntroText {
    color: var(--secundary);
    margin-bottom: 30px;
}

/* Hold eller nyhedsbrev knapper */

.signupNewsOrTeamSignupBtnContainer, .signupPartnerOrSoloBtnContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
}

.signupNewsOrTeamSignupBtnWrapper {
    width: fit-content;
    height: 80px;
    background-color: var(--primary);
    border-radius: 10px;
    border: 2px solid var(--orangeColor);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.signupTeamSignupBtnWrapper {
    width: 100% !important;
}

.signupPartnerOrSoloBtnWrapper {
    width: 300px;
    height: 80px;
    background-color: var(--primary);
    border-radius: 10px;
    border: 2px solid var(--orangeColor);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.signupTeamSignupBtnWrapper, .signupNewsSignupBtnWrapper, .signupPartnerSignupBtnWrapper, .signupSoloSignupBtnWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    height: 100%;
    transition: all 250ms ease;
    cursor: pointer;
    padding: 10px;
}

.signupTeamSignupBtn, .signupNewsSignupBtn, .signupPartnerSignupBtn, .signupSoloSignupBtn {
    display: block;
    text-align: center;
    font-weight: 500;
    color: var(--secundary);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.signupTeamSignupBtnWrapper.active, .signupNewsSignupBtnWrapper.active, .signupPartnerSignupBtnWrapper.active, .signupSoloSignupBtnWrapper.active {
    background-color: var(--orangeColor);
    box-shadow: 0px 0px 15px 3px var(--orangeColor50opacity);
    -webkit-box-shadow: 0px 0px 15px 3px var(--orangeColor50opacity);
    -moz-box-shadow: 0px 0px 15px 3px var(--orangeColor50opacity);
}

.signupTeamSignupBtn.active, .signupNewsSignupBtn.active, .signupPartnerSignupBtn.active, .signupSoloSignupBtn.active {
    color: var(--primary);
}

/* Input felterne */

.signupTeamWrapper, .signupNewsWrapper, .signupPartnerWrapper {
    width: 100%;
}

.signupMessageContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    height: 125px;
}

.signupMessageContainer .cfFormItemContainer {
    width: 100% !important;
    height: 100%;
}

.signupMessageContainer .cfFormItemContainer label {
    margin-bottom: 10px !important;
}

.signupMessageContainer textarea {
    resize: none;
    border-radius: 10px;
    outline: none;
    border: 1px solid var(--secundary50opacity);
    height: 100%;
    width: 100%;
    color: var(--orangeColor);
    padding: 10px;
}

.signupTeamGroupContainer, .signupNewsGroupContainer, .signupPartnerGroupContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    margin-bottom: 20px;
}

.cfFormItemContainer, .signupNewsContainer, .signupPartnerContainer {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.cfFormItemContainer>label {
    color: var(--secundary);
    margin-bottom: 5px;
}

.cfFormItemContainer>input {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--secundary50opacity);
    padding: 5px 5px 5px 0;
    outline: none;
    color: var(--orangeColor);
}



/* checkbox containeren */

.signupCheckboxContainer {
    background-color: #eee;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
}

.signupCheckboxWrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.signupCheckboxWrapper:first-child {
    margin-bottom: 20px;
}

.signupCheckboxText {
    font-size: 14px;
    color: var(--secundary);
}

.signupFreeTrialCheckboxContainer, .signupNewsletterCheckboxContainer {
    background-color: #fff;
    border: 1px solid var(--orangeColor);
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 5px;
    cursor: pointer;
}

.signupFreeTrialCheckboxContainer>img, .signupNewsletterCheckboxContainer>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.signupHidden {
    display: none;
}

.signupFormSubmitBtn {
    cursor: pointer;
}

.signupFormSubmitBtn:first-child {
    margin-right: 20px;
}

.signupSubmitBtnContainer {
    display: flex;
    flex-direction: column;
}

.signupFormContainerWrapper {
    display: flex;
}

/* ERROR MESSAGE STYLE */

.cfLabelErrorMsgContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.inputErrorMessage {
    color: var(--errorColor);
    display: flex;
    align-items: center;
}

.inputErrorMessage svg {
    margin-right: 3px;
}



.signupFormSuccesMsg {
    color: var(--succesColor);
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-bottom: 20px;
}

.signupFormSuccesMsg svg {
    margin-right: 3px;
}

.signupFormSubmitBtn {
    width: fit-content;
    cursor: pointer;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.signupFormSubmitBtn>svg {
    margin-right: 5px;
}


@media only screen and (max-width: 1240px) {
    .signupHeading {
        font-size: 29px;
    }
    .signupFormWrapper {
        padding: 50px;
    }
}

@media only screen and (max-width: 650px) {
    .signupHeading {
        font-size: 24px;
    }
    .signupFormWrapper {
        padding: 30px;
    }
    .signupIntroText, .signupTeamSignupBtn, .signupNewsSignupBtn, .signupTeamContainer>label, .signupNewsContainer>label, .signupPartnerContainer>label, .signupMessageContainer>label, .signupPartnerSignupBtn, .signupSoloSignupBtn {
        font-size: 14px;
    }
    .signupCheckboxText {
        font-size: 12px;
    }
    .signupMessageContainer {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .signupTeamGroupContainer, .signupPartnerGroupContainer {
        flex-direction: column;
        align-items: flex-start;
    }
    .signupTeamContainer, .signupPartnerContainer {
        width: 100%;
    }
    .signupFormContainerWrapper {
        flex-direction: column;
    }
    .signupFormSubmitBtn:first-child {
        margin-right: 0px;
        margin-bottom: 20px;
    }
}