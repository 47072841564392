#navbar {
    width: 100vw;
    height: 80px;
    background-color: var(--secundary);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
}

.navbarContainer {
    max-width: 1600px;
    width: 1600px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    gap: 2rem;
    padding: 0 2rem;
    margin: 0 auto;
    position: relative;
}

.navbarLogoContainer {
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.navbarLogoContainer span {
    color: var(--primary);
    margin-left: 20px;
    line-height: 1.5;
}

.navbarLogoContainer a img {
    width: auto;
    height: 100%
}

.navbarLinksContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
    position: relative;
    width: 100%;
    height: 100%;
    transition: all 250ms ease-in-out;
}

.navbarLinksWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
    position: relative;
    height: 100%;
    list-style: none;
}

.navbarLinksWrapper>div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
    position: relative;
    width: 100%;
    height: 100%;
}

.navbarLinksWrapper>div>li {
    width: 100%;
    position: relative;
}

.navbarLinksWrapper>div>li::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 0%;
    height: 2px;
    background-color: var(--orangeColor);
    transition: width 250ms ease-in-out;
    box-shadow: 0px 0px 3px 0px rgba(196, 112, 82, 1);
    -webkit-box-shadow: 0px 0px 3px 0px rgba(196, 112, 82, 1);
    -moz-box-shadow: 0px 0px 3px 0px rgba(196, 112, 82, 1);
}

.navbarLinksWrapper>div>li:hover::before {
    width: 100%;
    background-color: var(--orangeColor50opacity);
}

.navbarLinksWrapper>div>li.active::before {
    width: 100%;
}

.navbarLinksWrapper>div>li>a {
    white-space: nowrap;
}

.navbarLinksContainer.active {
    right: 0;
}

.navbarLinksWrapper>div>li>a {
    text-decoration: none;
    color: var(--orangeColor);
    font-weight: 500;
}

@media only screen and (max-width: 964px) {
    .navbarLinksContainer {
        position: absolute;
        top: 70px;
        right: -100%;
        z-index: 100;
        width: 50%;
        height: 100vh;
        background-color: var(--secundary);
    }
    .navbarLinksWrapper {
        justify-content: center;
        width: 100%;
    }
    .navbarLinksWrapper>div {
        margin-top: 200px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: fit-content;
        gap: 2.5rem;
    }
    .navbarLinksWrapper>div>li>a {
        font-size: 18px;
        position: relative;
    }
    .navbarLinksWrapper>div>li>a::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -30px;
        width: 10px;
        height: 2px;
        background-color: var(--orangeColor50opacity);
    }
}

@media only screen and (max-width: 640px) {
    .navbarLinksContainer {
        width: 100%;
    }
}