#homeCalender {
    max-width: 1600px;
    height: 100vh;
    margin: 0 auto;
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homeCalenderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 2rem;
}

.homeCalenderMediaContainer {
    width: calc((100% / 2));
    position: relative;
}


.homeCalenderMediaContainer>img, .homeCalenderMediaContainer>video {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 10;
}

.homeCalenderMediaContainer::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--secundary);
    bottom: -15px;
    left: -15px;
}

.homeCalenderTextContainer {
    width: calc((100% / 2) - 50px);
    position: relative;
}

.homeCalenderTextContainer>img {
    position: absolute;
    top: -40%;
    left: 0;
    width: 100%;
    height: auto;
}

.homeCalenderHeading {
    color: var(--secundary);
    font-weight: 500;
    font-size: 42px;
    margin-bottom: 30px;
    position: relative;
    z-index: 10;
}

.homeCalenderText {
    color: var(--secundary);
    line-height: 1.3;
    margin-bottom: 50px;
    position: relative;
    z-index: 10;
}

.homeCalenderBtn {
    position: relative;
    z-index: 10;
}

@media only screen and (max-width: 1200px) {
    .homeCalenderHeading {
        font-size: 36px;
    }
}

@media only screen and (max-width: 1050px) {
    .homeCalenderHeading {
        font-size: 32px;
    }
}

@media only screen and (max-width: 960px) {
    .homeCalenderContainer {
        flex-direction: column;
        justify-content: flex-start;
    }
    .homeCalenderTextContainer {
        width: 100%;
    }
    .homeCalenderMediaContainer {
        width: 95%;
        margin-bottom: 50px;
    }
    .homeCalenderTextContainer>img {
        display: none;
    }
}

@media only screen and (max-width: 500px) {
    .homeCalenderTextContainer .btn {
        width: 100%;
        display: block;
        text-align: center;
    }
    .homeCalenderHeading {
        font-size: 26px;
    }
    
}