#homeReview {
    max-width: 1600px;
    height: 100vh;
    margin: 0 auto;
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homeReviewContainer {
    width: 100%;
    height: 100%;
    padding: 0 1.2rem;
    position: relative;
}

.homeReviewContainer>img {
    position: absolute;
    top: -20%;
    left: -10%;
    width: 900px;
    opacity: .2;
}

.homeReviewTopWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 10;
}

.homeReviewHeading {
    font-size: 49px;
    color: var(--secundary);
    max-width: 700px;
    font-weight: 500;
}

.homeReviewSlideBtnContainer {
    position: absolute;
    top: 50px;
    right: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.homeReviewLeftArrowWrapper, .homeReviewRightArrowWrapper {
    width: 50px;
    height: 50px;
    padding: 10px;
    margin: 0 20px;
    cursor: pointer;
    transition: all 250ms ease;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homeReviewLeftArrowWrapper::before, .homeReviewRightArrowWrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--orangeColor50opacity);
    border: 1px solid var(--secundary);
    transform: rotate(45deg);
    transition: all 250ms ease-in-out;
}

.homeReviewLeftArrowWrapper>svg, .homeReviewRightArrowWrapper>svg {
    width: 90%;
    height: 90%;
    color: var(--secundary);
    position: relative;
    z-index: 10;
}

.homeReviewLeftArrowWrapper:hover::before {
    transform: rotate(225deg);
}

.homeReviewRightArrowWrapper:hover::before {
    transform: rotate(225deg);
}

.homeReviewLeftArrowWrapper.disable {
    opacity: 0.7;
}

.homeReviewReviewsContainer {
    width: 100%;
    padding: 75px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.homeReviewSingleReviewContainer {
    margin: 0 20px;
    padding: 50px;
    border-radius: 10px;
    background-color: var(--white);
    position: relative;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2);
}



.homeReviewSingleReviewContainer>svg {
    position: absolute;
    width: 70px;
    height: auto;
    top: -40px;
    right: 20px;
    color: var(--orangeColor);
}

.homeReviewSingleReviewTopContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 20px;
}

.homeReviewSingleReviewImageWrapper {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2);
}

.homeReviewSingleReviewImageWrapper>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.homeReviewSingleReviewHeading {
    color: var(--orangeColor);
    font-weight: 600;
}

.homeReviewSingleReviewTeam {
    color: var(--secundary);
    font-size: 12px;
}

.homeReviewSingleReviewText {
    color: var(--secundary);
}

@media only screen and (max-width: 1024px) {
    .homeReviewSlideBtnContainer {
        display: none;
    }
    .homeReviewHeading {
        font-size: 39px;
    }
}

@media only screen and (max-width: 800px) {
    .homeReviewContainer>img {
        width: 700px;
    }
}

@media only screen and (max-width: 600px) {
    .homeReviewHeading {
        font-size: 29px;
    }
    .homeReviewSingleReviewText {
        font-size: 14px;
    }
    .homeReviewSingleReviewHeading {
        font-size: 16px;
    }
    .homeReviewSingleReviewTeam {
        font-size: 11px;
    }
    .homeReviewContainer>img {
        top: -20%;
        left: -20%;
        width: 700px;
    }
}

@media only screen and (max-width: 410px) {
    .homeReviewSingleReviewContainer {
        padding: 20px;
    }
}