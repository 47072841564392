#danceCategoryHero {
    max-width: 1600px;
    height: fit-content;
    margin: 70px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
}

.danceCategoryContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.danceCategoryHeroContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 1.2rem 100px;
}

.danceCategoryHeroMediaContainer {
    width: calc((100% / 2));
    position: relative;
}

.danceCategoryHeroMediaContainer>video {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 10;
}

.danceCategoryHeroMediaContainer::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--secundary);
    bottom: -15px;
    left: -15px;
}

.danceCategoryHeroTextContainer {
    width: calc((100% / 2) - 100px);
    position: relative;
}

.danceCategoryHeroTextContainer>img {
    position: absolute;
    top: -50%;
    left: 0%;
    width: 100%;
    height: auto;
}

.danceCategoryHeroHeading {
    color: var(--secundary);
    font-weight: 500;
    font-size: 42px;
    margin-bottom: 30px;
}

.danceCategoryHeroText {
    color: var(--secundary);
    line-height: 1.3;
    margin-bottom: 30px;
}








.danceCategoryMainContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    padding: 0 1.2rem;
    gap: 2rem;
    margin: 100px 0;
}

.danceCategorySingleContainer {
    width: calc((100% / 4) - 2rem);
    height: 350px;
    position: relative;
    z-index: 0;
    transition: all 500ms ease-in-out;
}

.danceCategorySingleContainer>article {
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
    background-color: var(--white);
}

.danceCategorySingleHoverContainer {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: transparent;
    border: 1px solid var(--secundary);
    transition: transform 500ms ease-in-out;
    transform: rotate(0deg);
    opacity: 0;
}

.danceCategorySingleContainer:hover .danceCategorySingleHoverContainer {
    transform: rotate(-5deg);
    opacity: 1;
}

.danceCategorySingleContainer:hover {
    transform: scale(1.05);
}

.danceCategorySingleHeading {
    color: var(--orangeColor);
    font-weight: 600;
    margin-bottom: 10px;
    position: relative;
    z-index: 10;
}

.danceCategorySingleText {
    color: var(--secundary);
    margin-bottom: 10px;
}

.danceCategorySingleDayTimeContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
}

.danceCategorySingleDayTimeWrapper {
    background-color: transparent;
    border: 1px solid var(--orangeColor70opacity);
    border-radius: 10px;
    padding: 10px;
    width: calc(100% / 2);
    text-align: center;
}

.danceCategorySingleDayTimeText {
    color: var(--orangeColor);
    font-weight: 500;
    word-break: keep-all;
    white-space: nowrap;
}

.danceCategorySingleBtn {
    font-size: 16px;
    padding: 16px 32px;
    font-weight: 500;
    text-align: center;
    border-radius: 10px;
}

.danceCategorySingleBtnEmpty {
    font-size: 16px;
    padding: 16px 32px;
    font-weight: 500;
    text-align: center;
    border-radius: 10px;
    cursor: initial;
}

.danceCategorySingleBtn:hover {
    background-color: var(--orangeColor) !important;
    color: var(--primary) !important;
    border-color: var(--orangeColor) !important;
} 









@media only screen and (max-width: 1400px) {
    .danceCategoryContainer {
        gap: 1rem;
    }
    .danceCategoryMainContainer {
        gap: 1rem;
    }
    .danceCategorySingleContainer {
        width: calc((100% / 3) - 1rem);
    }
    .danceCategoryHeroTextContainer>img {
        top: -20%;
    }
}

@media only screen and (max-width: 1240px) {
    .danceCategorySingleContainer {
        width: calc((100% / 3) - 1rem);
    }
}

@media only screen and (max-width: 1024px) {
    #danceCategoryHero {
        height: fit-content;
    }
    .danceCategoryHeroContainer {
        flex-direction: column;
    }
    .danceCategoryHeroMediaContainer {
        width: 90%;
        margin-bottom: 50px;
    }
    .danceCategoryHeroTextContainer {
        width: 90%;
    }
    .danceCategoryHeroTextContainer>img {
        display: none;
    }
}

@media only screen and (max-width: 964px) {
    .danceCategoryContainer {
        gap: 2rem;
    }
    .danceCategorySingleContainer {
        width: calc((100% / 2) - 1rem);
    }
}

@media only screen and (max-width: 650px) {
    .danceCategoryContainer {
        gap: 1rem;
    }
    .danceCategorySingleContainer {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .danceCategoryHeroHeading {
        font-size: 29px;
    }
    .danceCategoryHeroText {
        font-size: 14px;
    }
    .danceCategoryHeroMediaContainer {
        width: 95%;
        margin-bottom: 50px;
    }
    .danceCategoryHeroTextContainer {
        width: 100%;
    }
    .danceCategorySingleBtn {
        font-size: 14px;
        padding: 10px 28px;
    }
    .danceCategorySingleText, .danceCategorySingleDayTimeText {
        font-size: 14px;
    }
}

