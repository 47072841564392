#contact {
    max-width: 1200px;
    height: calc(100vh - 70px);
    margin: 0 auto;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
}

.contactContainer {
    width: 100%;
    height: fit-content;
    padding: 0 1.2rem;
}

.contactHeading {
    text-align: center;
    font-size: 49px;
    color: var(--orangeColor);
    margin-bottom: 20px;
}

.contactSubheading {
    color: var(--secundary);
    text-align: center;

}

/* CARD CONTAINER */
.contactFormContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
    margin: 50px 0;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.25);
    -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.25);
    width: 100%;
    height: 425px;
}

/* TEXT CONTAINER */

.contactInfoContainer {
    width: 40%;
    height: 100%;
    background-color: var(--secundary70opacity);
    padding: 50px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
}

.contactInfoContainer::before {
    content: '';
    position: absolute;
    bottom: 50px;
    right: 20px;
    width: 150px;
    height: 150px;
    background-color: #4b558b;
    z-index: 10;
    border-radius: 50%;
}

.contactInfoContainer::after {
    content: '';
    position: absolute;
    bottom: 150px;
    right: 100px;
    width: 100px;
    height: 100px;
    background-color: #c76c4b;
    z-index: 10;
    border-radius: 50%;
}

.contactInfoHeading{
    color: var(--primary);
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 28px;
}

.contactInfoText{
    color: var(--primary);
    opacity: 0.8;
    line-height: 1.3;
}

.contactInfoSingleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
    z-index: 100;
}

.contactInfoSingleContainer:last-child {
    margin-bottom: 0px;
}

.contactInfoIcon {
    width: 25px;
    height: auto;
    margin-right: 25px;
}

.contactInfoTextloop {
    font-size: 14px;
    color: var(--primary);
    opacity: 0.8;
}

/* FORM CONTAINER */

.contactFormWrapper {
    width: 60%;
    height: 100%;
    padding: 50px 50px 50px 0;
}

.cf1FormWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cf1FormItemGroupContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
    width: 100%;
}

.cf1FormItemMessageContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100px;
}

.cfFormItemContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 50%;
}

.cf1FormItemMessageContainer .cfFormItemContainer {
    width: 100%;
    height: 100%;
}

.cfFormItemContainer label, .cf1FormItemMessageContainer label {
    font-size: 14px;
    color: var(--secundary);
}

.cfFormItemContainer input, .cf1FormItemMessageContainer textarea {
    width: 100%;
    height: 100%;
    border: none;
    border-bottom: 1px solid var(--secundary50opacity);
    outline: none;
    padding: 10px 5px;
    font-size: 14px;
    color: var(--orangeColor);
}

.cf1FormItemMessageContainer label {
    margin-bottom: 10px;
}

.cf1FormItemMessageContainer textarea {
    resize: none;
    border: 1px solid var(--secundary50opacity);
    border-radius: 5px;
}

.cf1FormSubmitBtn {
    width: fit-content;
    cursor: pointer;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.cf1FormSubmitBtn>svg {
    margin-right: 5px;
}


/* ERROR MESSAGE STYLE */

.cfLabelErrorMsgContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.inputErrorMessage {
    color: var(--errorColor);
    display: flex;
    align-items: center;
    font-size: 14px;
}

.inputErrorMessage svg {
    margin-right: 3px;
}

.cf1FormBtnContainer {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
}

.cf1FormSuccesMsg {
    color: var(--succesColor);
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-left: 20px;
}

.cf1FormSuccesMsg svg {
    margin-right: 3px;
}



/* MEDIA QUERYS */

@media only screen and (max-width: 1024px) {
    #contact {
        padding: 50px 0;
        height: fit-content;
    }
    .contactInfoHeading {
        font-size: 24px;
    }
    .contactInfoText {
        font-size: 14px;
    }
}

@media only screen and (max-width: 964px) {
    .contactFormContainer {
        flex-direction: column;
        height: fit-content;
        gap: 1rem;
    }
    .contactInfoContainer {
        width: 100%;
        border-radius: 10px 10px 0 0;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        
    }
    .contactInfoTopContainer {
        width: 40%;
    }
    .contactInfoContainer::before {
        bottom: 50px;
        right: 20px;
        width: 100px;
        height: 100px;
    }
    
    .contactInfoContainer::after {
        bottom: 120px;
        right: 75px;
        width: 65px;
        height: 65px;
    }
    .contactFormWrapper {
        width: 100%;
        height: 100%;
        padding: 20px 50px 50px 50px;
    }
    .cf1FormWrapper {
        gap: 2rem;
    }
}

@media only screen and (max-width: 690px) {
    .contactInfoTopContainer {
        width: 60%;
    }
    .contactSubheading {
        font-size: 14px;
    }
    .contactHeading {
        font-size: 39px;
    }
}

@media only screen and (max-width: 600px) {
    .contactInfoTopContainer {
        width: 100%;
    }
    .contactInfoContainer {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 2rem;
    }
    .cf1FormSubmitBtn {
        width: 100%;
    }
    .cf1FormItemGroupContainer {
        flex-direction: column;
    }
    .cfFormItemContainer {
        width: 100%;
    }
    .cf1FormItemMessageContainer {
        height: 150px;
    }
    .cf1FormBtnContainer {
        flex-direction: column;
    }
    .cf1FormSuccesMsg {
        margin-bottom: 10px;
    }
}


@media only screen and (max-width: 420px) {
    .contactInfoContainer {
        padding: 30px;
    }
    .contactFormWrapper {
        padding: 30px;
    }
}