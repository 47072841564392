#homeHero {
    max-width: 1600px;
    height: calc(100vh - 69px);
    margin: 0 auto;
    padding: 100px 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
    position: relative;
    z-index: 10;
}

.homeHeroContainer {
    width: 100%;
    height: 100%;
    position: relative;
}

.homeHeroContainer>img {
    position: absolute;
    width: auto;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
}

.homeHeroFlexContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* gap: 2rem 4rem; */
    width: 100%;
    height: 100%;
    position: relative;
}

.homeHeroTextContainer{
    width: 40%;
    position: relative;
    padding: 1rem;
}


.homeHeroMediaContainer {
    width: 60%;
    height: auto;
    position: relative;
    padding: 1rem;
}

.homeHeroMediaContainer>video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 10;
}

.homeHeroSquareDesign {
    position: absolute;
    z-index: 1;
    width: 400px;
    height: auto;
    opacity: 0.6;
}

.homeHeroSquareDesign.orange {
    bottom: -20%;
    right: -15%;
}

.homeHeroSquareDesign.dark {
    top: -20%;
    left: -17%;
}


.homeHeroHeading {
    position: relative;
    z-index: 10;
    font-size: 62px;
    color: var(--secundary);
    margin-bottom: 2rem;
    font-weight: 500;
}

.homeHeroText {
    position: relative;
    z-index: 10;
    line-height: 1.4;
    margin-bottom: 50px;
    color: var(--secundary);
}

.homeHeroBtn {
    position: relative;
    z-index: 10;
}

@media only screen and (max-width: 1024px) {
    #homeHero {
        padding: 0;
    }
    .homeHeroContainer>img {
        display: none;
    }
    .homeHeroHeading {
        font-size: 48px;
        text-align: center;
        color: var(--primary);
    }
    .homeHeroMediaContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 0;
        height: 100%;
    }
    .homeHeroMediaContainer::before {
        content: '';
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.39);
    }
    .homeHeroTextContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .homeHeroTextWrapper {
        width: 100%;
        height: fit-content;
        padding: 50px;
        z-index: 10;
        max-width: 700px;
        background-color: rgba(53, 54, 68, 0.5);
    }
    .homeHeroTextContainer .btn {
        text-align: center;
    }
    .homeHeroText {
        color: var(--primary);
    }
    .homeHeroBtn.btn:hover {
        background-color: var(--orangeColor) !important;
        border-color: var(--orangeColor) !important;
        color: var(--secundary) !important;
    }
    .homeHeroSquareDesign {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .homeHeroTextContainer {
        padding: 0;
    }
    .homeHeroTextWrapper {
        padding: 50px 30px;
    }
}

