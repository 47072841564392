#f1 {
    width: 100%;
    height: fit-content;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secundary);
}

.f1Container {
    max-width: 1600px;
    width: 1600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2rem;
}

/* Top Container */

.f1TopContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 75px 0;
}

.f1TopWrapper:first-child {
    width: 400px;
    line-height: 1.3;
}

.f1LogoContainer {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
}

.f1Logo {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.f1Text {
    color: var(--primary);
    margin-bottom: 40px;
}

.f1EmailBtn.btn {
    border: 1px solid var(--orangeColor);
    background-color: var(--orangeColor);
    color: var(--primary);
    margin-right: 20px;
}

.f1EmailBtn.btn:hover {
    background-color: transparent;
    color: var(--orangeColor);
}

.f1PhoneBtn.btn {
    border: 1px solid var(--orangeColor);
}

.f1PhoneBtn.btn:hover {
    background-color: var(--orangeColor);
    color: var(--primary);
}

.f1Heading {
    color: var(--orangeColor);
    margin-bottom: 20px;
    font-weight: 600;
}

.f1Adress {
    line-height: 1.5;
    color: var(--primary);
    margin-bottom: 20px;
}

.f1MenuContainer, .f1TeamContainer {
    display: flex;
    flex-direction: column;
}

.f1MenuContainer>a, .f1TeamContainer>a {
    text-decoration: none;
    color: var(--primary);
    margin-bottom: 10px;
    opacity: 0.8;
    transition: all 500ms ease;
    position: relative;
}

.f1MenuContainer>a:hover, .f1TeamContainer>a:hover {
    transform: translateX(25px);
    opacity: 1;
}

.f1MenuContainer>a:hover::before, .f1TeamContainer>a:hover::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -20px;
    width: 10px;
    height: 2px;
    background-color: var(--primary);
}

.f1TopFlexWrapper>iframe {
    border: none;
    outline: none;
    width: 100%;
}

/* Bottom contyainer */

.f1BottomContainer {
    width: 80%;
    text-align: center;
    padding: 30px;
    border-top: 1px solid var(--orangeColor50opacity);
}

.f1CopyRightText {
    color: var(--orangeColor50opacity);
    font-size: 14px;
    font-weight: 500;
}


@media only screen and (max-width: 1040px) {
    .f1TopContainer {
        flex-wrap: wrap;
        gap: 6rem 0;
    }
    .f1TopWrapper {
        width: 50%;
    }
}

@media only screen and (max-width: 880px) {
    .f1Text, .f1Adress, .f1MenuContainer>a, .f1TeamContainer>a {
        font-size: 14px;
    }
    .f1TopWrapper:first-child {
        padding-right: 50px;
        width: 50%;
    }
}

@media only screen and (max-width: 800px) {
    .f1TopWrapper:first-child {
        width: 100%;
    }
    .f1TopWrapper:first-child .f1TopFlexWrapper {
        width: 400px;
    }
    .f1TopWrapper:nth-child(2) {
        width: 50%;
    }
    .f1TopWrapper:nth-child(3), .f1TopWrapper:nth-child(4) {
        width: 25%;
    }
}

@media only screen and (max-width: 700px) {
    .f1TopWrapper:first-child {
        padding-right: 0px;
    }
    .f1TopWrapper:nth-child(2) {
        width: 100%;
    }
    .f1TopWrapper:nth-child(3), .f1TopWrapper:nth-child(4) {
        width: 50%;
    }
    .f1BottomContainer {
        width: 100%;
        padding: 30px 0;
    }
}

@media only screen and (max-width: 460px) {
    .f1TopWrapper:first-child .f1TopFlexWrapper {
        width: 100%;
    }
    .f1CopyRightText {
        font-size: 12px;
    }
}

@media only screen and (max-width: 420px) {
    .f1BtnContainer {
        flex-direction: column;
    }
    .f1BtnContainer .f1EmailBtn {
        margin-bottom: 20px;
    }

    .f1BtnContainer .btn {
        text-align: center;
    }
}