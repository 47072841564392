#about {
    max-width: 1400px;
    height: 100vh;
    margin: 70px auto 0;
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 1.2rem;
}

/* TEXT CONTAINER */

.aboutTextContainer {
    width: 50%;
}

.aboutHeading {
    color: var(--orangeColor);
    font-size: 49px;
    margin-bottom: 20px;
}

.aboutText {
    line-height: 1.3;
    color: var(--secundary);
    margin-bottom: 50px;
}

.aboutFunfactContainer {
    display: flex;
    align-items: center;
    height: 150px;
    width: 100%;
}

.aboutSingleFunfactContainer {
    width: calc(100% / 3);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutSingleFunfactFlexWrapper {
    text-align: center;
}

.aboutSingleFunfactContainer:first-child {
    border-radius: 10px 0 0 10px;
}

.aboutSingleFunfactContainer:last-child {
    border-radius: 0 10px 10px 0;
}

.aboutSingleFunfactContainer:nth-child(odd) {
    background-color: var(--orangeColor50opacity);
    /* border: 2px solid var(--okrangeColor); */
}

.aboutSingleFunfactContainer:nth-child(even) {
    background-color: var(--orangeColor70opacity);
    /* border: 2px solid var(--orangeColor); */
}

.aboutSingleFunfactContainer .aboutSingleFunfactHeading {
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--secundary);
    opacity: 0.8;
}

.aboutSingleFunfactContainer .aboutSingleFunfactText {
    font-size: 30px;
    color: var(--secundary);
    font-weight: 600;
}



/* MEDIA CONTAINER */

.aboutMediaContainer {
    width: 35%;
    position: relative;
}

.aboutMediaContainer::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--secundary);
    bottom: -15px;
    left: -15px;
}

.aboutMediaContainer>img {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 10;
}


/* MEDIA QUERYS */

@media only screen and (max-width: 1024px) {
    #about {
        padding: 50px 0;
        height: fit-content;
        margin-bottom: 100px;
    }
    .aboutContainer {
        flex-direction: column;
        height: fit-content;
    }
    .aboutTextContainer {
        width: 100%;
        margin-bottom: 50px;
    }
    .aboutMediaContainer {
        width: 50%;
    }
}

@media only screen and (max-width: 600px) {
    .aboutMediaContainer {
        width: 95%;
    }
    .aboutHeading {
        font-size: 32px;
    }
    .aboutText {
        font-size: 14px;
    }
    .aboutSingleFunfactHeading {
        font-size: 16px;
    }
    .aboutSingleFunfactContainer .aboutSingleFunfactText {
        font-size: 24px;
    }
    .aboutFunfactContainer {
        height: 120px;
    }
}

@media only screen and (max-width: 420px) {
    .aboutSingleFunfactHeading {
        font-size: 14px;
    }
    .aboutSingleFunfactContainer .aboutSingleFunfactText {
        font-size: 22px;
    }
    .aboutFunfactContainer {
        height: 120px;
    }
}