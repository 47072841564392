#homeFaq {
    max-width: 1400px;
    height: 100vh;
    margin: 0 auto;
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homeFaqContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 1.2rem;
}

.homeFaqImageContainer {
    width: calc((100% / 2));
    position: relative;
}

.homeFaqImageContainer>img {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 10;
}

/* .homeFaqImageContainer::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--secundary);
    bottom: -15px;
    left: -15px;
} */

.homeFaqTextContainer {
    width: calc((100% / 2) - 50px);
}

.homeFaqHeading {
    color: var(--secundary);
    font-weight: 500;
    font-size: 42px;
    margin-bottom: 30px;
}

.homeFaqText {
    color: var(--secundary);
    line-height: 1.3;
    margin-bottom: 30px;
}

.homeFaqFaqContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 20px;
}

.homeFaqFaqSingleWrapper {
    border-bottom: 1px solid var(--orangeColor);
    padding: 0 50px 20px 0;
    margin-bottom: 20px;
}

.homeFaqFaqTopContainer {
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.homeFaqFaqIconWrapper {
    width: 15px;
    height: 15px;
}

.homeFaqFaqIconWrapper>img {
    width: 100%;
    height: auto;
}

.homeFaqFaqQuestion {
    color: var(--orangeColor);
    font-weight: 600;
}

.homeFaqFaqAnwerser {
    color: var(--secundary);
    opacity: 0;
    max-height: 0px;
    overflow: hidden;
    transition: all 500ms ease-in-out;
}

.homeFaqFaqAnwerser.open {
    opacity: 1;
    max-height: 1000px;
}

.homeFaqBtnsText{
    color: var(--secundary);
    margin-bottom: 40px;
}

.homeFaqBtnContainer {
    margin-top: 30px;
}

.homeFaqBtnContainer .btn:first-child {
    margin-right: 10px;
}

.homeFaqBtnContainer .btn:last-child {
    margin-left: 10px;
}

@media only screen and (max-width: 1024px) {
    #homeFaq {
        height: fit-content;
    }
    .homeFaqContainer {
        flex-direction: column;
    }
    .homeFaqImageContainer {
        width: 70%;
        margin-bottom: 50px;
    }
    .homeFaqTextContainer {
        width: 100%;
    }
    .homeFaqFaqSingleWrapper {
        padding: 0 20px 20px 0;
    }
    .homeFaqFaqAnwerser {
        padding-right: 20px;
    }
}

@media only screen and (max-width: 600px) {
    .homeFaqImageContainer {
        width: 100%;
        margin-bottom: 50px;
    }
    .homeFaqBtnContainer{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .homeFaqBtnContainer .btn {
        display: block;
        width: 50%;
        text-align: center;
    }
    .homeFaqHeading {
        font-size: 32px;
    }
    .homeFaqText, .homeFaqFaqQuestion, .homeFaqFaqAnwerser, .homeFaqBtnsText {
        font-size: 14px;
    }
}

@media only screen and (max-width: 420px) {
    .homeFaqHeading {
        font-size: 28px;
    }
    .homeFaqBtnContainer{
        flex-direction: column;
    }
    .homeFaqBtnContainer .btn:first-child {
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
    }
    .homeFaqBtnContainer .btn:last-child {
        width: 100%;
        margin: 0;
    }
}