#g1Top {
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.g1TopContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 1.2rem;
    margin-bottom: 200px;
    margin-top: 100px;
}

.g1TopMediaContainer {
    width: calc((100% / 2));
    position: relative;
}

.g1TopMediaContainer>img {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 10;
}

.g1TopMediaContainer::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--secundary);
    bottom: -15px;
    left: -15px;
}

.g1TopTextContainer {
    width: calc((100% / 2) - 100px);
    position: relative;
}

.g1TopTextContainer>img {
    position: absolute;
    bottom: -100%;
    left: -10%;
    width: 100%;
    height: auto;
}

.g1TopHeading {
    color: var(--orangeColor);
    font-size: 42px;
    margin-bottom: 30px;
}

.g1TopText {
    color: var(--secundary);
    line-height: 1.3;
    margin-bottom: 30px;
}












#g1 {
    width: 100vw;
    height: fit-content;
    margin: 0 auto;
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.g1MainContainer {
    max-width: 1600px;
    width: 1600px;
    height: fit-content;
    padding: 0 2rem;
}

.g1Container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

/* Gallery navigation */

.g1NavigationContainer{
    width: 300px;   
    height: fit-content;
}

.g1NavigationCategoryContainer {
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    margin-bottom: 20px;
}

.g1TopNavigationWrapper, .g1SubNavigationWrapper {
    border: 1px solid var(--secundary);
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.g1TopNavigationWrapper{
    width: 100%;
}

.g1TopNavigationHeading {
    color: var(--orangeColor);
    font-weight: 500;
    user-select: none;
    pointer-events: none;
    white-space: nowrap;
}

.g1SubNavigationContainer {
    width: calc(100% - 30px);
    height: fit-content;
    transform: translateX(30px);
    margin-top: 10px;
}

.g1SubNavigationWrapper {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
}

.g1SubNavigationWrapper::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 55px;
    background-color: var(--secundary50opacity);
    top: -11px;
    left: -15px;
}

.g1SubNavigationWrapper:last-child:before {
    height: 32px;
}

.g1SubNavigationWrapper::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 15px;
    background-color: var(--secundary50opacity);
    top: 50%;
    left: -15px;
}

.g1SubNavigationWrapper.active {
    background-color: var(--secundary);
}

.g1SubNavigationHeading {
    color: var(--secundary);
    user-select: none;
    pointer-events: none;
    white-space: nowrap;
}

.g1SubNavigationWrapper.active .g1SubNavigationHeading {
    color: var(--orangeColor);
}

.g1TestContainer {
    transition: all 250ms ease-in-out;
    width: 100%;
    height: fit-content;
    overflow: hidden;
}

.g1TopNavigationWrapper.active ~ .g1TestContainer {
    height: 0px;
}

/* Gallery container */

.g1ImageGalleryContainer {
    width: 80%;
    height: fit-content;
}

.g1ImagesHeading {
    text-align: center;
    margin-bottom: 50px;
    font-size: 34px;
    color: var(--orangeColor);
    font-weight: 600;
}

.g1ImagesContainer {
    width: 100%;
    height: inherit;
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    flex-wrap: wrap;
}

.g1ImageWrapper {
    width: calc((100% / 4) - 20px);
    height: 250px;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
}

.g1Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    pointer-events: none;
    transform: scale(1);
    transition: all 250ms ease;
}

.g1ImageWrapper:hover .g1Image {
    transform: scale(1.1);
}

/* Modal container */

.g1ModalContainer {
    position: fixed;
    top: 70px;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    padding: 100px 0;
}

.g1ModalContainer.active {
    display: flex;
}

.g1CloseModalIcon {
    position: absolute;
    top: 50px;
    right: 50px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all 250ms ease;
}

.g1CloseModalIcon:hover {
    transform: scale(1.15);
}

.g1ModalWrapper {
    max-width: 1000px;
    width: 1000px;
    max-height: 90%;
    height: 90%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.g1ModalArrow {
    width: 40px;
    height: auto;
    color: var(--orangeColor);
    cursor: pointer;
    transition: all 250ms ease;
}

.g1ModalArrow:hover {
    transform: scale(1.15);
}

.g1ModalImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: 0 10px;
}

@media only screen and (max-width: 1460px) {
    .g1TopTextContainer>img {
        bottom: -50%;
        left: -10%;
    }
}

@media only screen and (max-width: 1200px) {
    .g1TopTextContainer>img {
        bottom: -10%;
        left: -10%;
    }
}


@media only screen and (max-width: 1024px) {
    #g1Top {
        height: fit-content;
    }
    .g1TopContainer {
        flex-direction: column-reverse;
    }
    .g1TopMediaContainer {
        width: 90%;
        margin-bottom: 50px;
    }
    .g1TopTextContainer {
        width: 90%;
    }
    .g1TopTextContainer>img {
        display: none;
    }
    .g1ImageWrapper {
        width: calc((100% / 3) - 20px);
    }
    .g1CloseModalIcon {
        top: 20px;
        right: 20px;
    }
}

@media only screen and (max-width: 864px) {
    .g1ImageWrapper {
        width: calc((100% / 2) - 20px);
    }
    .g1TopNavigationHeading, .g1SubNavigationHeading {
        font-size: 14px;
    }
}

@media only screen and (max-width: 700px) {
    .g1Container {
        flex-direction: column;
    }
    .g1ImageGalleryContainer {
        width: 100%;
    }
    .g1NavigationContainer{
        width: 100%;
    }
    .g1ImagesHeading {
        font-size: 28px;
    }
}

@media only screen and (max-width: 600px) {
    .g1TopHeading {
        font-size: 29px;
    }
    .g1TopText {
        font-size: 14px;
    }
    .g1TopMediaContainer {
        width: 95%;
        margin-bottom: 50px;
    }
    .g1TopTextContainer {
        width: 100%;
    }
}

@media only screen and (max-width: 420px) {
    .g1ImageWrapper {
        width: 100%;
    }
}