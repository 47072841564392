#homeTeam {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
}

.homeTeamContainer {
    max-width: 1600px;
    width: 1600px;
    padding: 0 2rem;
}

.homeTeamHeading {
    font-size: 49px;
    color: var(--orangeColor);
    margin-bottom: 50px;
}

.homeTeamFlexWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.homeTeamTextContainer {
    max-width: 500px;
    width: 40%;
    margin-bottom: 50px;
    position: relative;
}

.homeTeamTextContainer>img:first-child {
    position: absolute;
    top: 40%;
    left: 30%;
    width: 775px;
    transform: translate(-50%, -50%);
    opacity: .4;
}

.homeTeamText {
    line-height: 1.3;
    color: var(--secundary);
    margin-bottom: 50px;
}

.homeTeamBtnContainer {
    display: flex;
    align-items: center;
}

.homeTeamBtnContainer>a:first-child {
    margin-right: 10px;
}

.homeTeamBtnContainer>a:last-child {
    margin-left: 10px;
}

.homeTeamCatContainer {
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.homeTeamCatCardContainer {
    width: calc((100% / 2) - 20px);
    margin-bottom: 40px;
    height: 400px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: flex-end;
}

.homeTeamCatCardPulsingIcon {
    width: 40px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 50;
    transform: translate(-50%, -50%);
    animation-name: pulsing;
    animation-duration: 1.0s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
    visibility: visible;
}

.homeTeamCatCardPulsingIcon.hide {
    visibility: hidden;
}

.homeTeamCatCardContainer>video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.homeTeamCatCardTextContainer {
    position: relative;
    width: 100%;
    z-index: 10;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(8,8,8);
    background: linear-gradient(0deg, rgba(8,8,8,1) 0%, rgba(8,8,8,0) 100%); 
}

.homeTeamCatCardHeading {
    color: var(--orangeColor);
    font-size: 16px;
    font-weight: 600;
}

.homeTeamProgramBtn, .homeTeamPriceBtn {
    position: relative;
    z-index: 10;
}

.homeTeamPriceBtn.btn {
    background-color: var(--primary) !important;
}

.homeTeamPriceBtn.btn:hover {
    background-color: var(--secundary) !important;
}

@media only screen and (max-width: 1200px) {
    .homeTeamCatContainer {
        width: 50%;
    }
    .homeTeamFlexWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .homeTeamCatCardContainer {
        width: calc((100% / 2) - 10px);
        height: 400px;
        margin-bottom: 20px;
    }
    .homeTeamCatCardTextContainer {
        flex-direction: column;
    }
    .homeTeamCatCardHeading {
        margin-bottom: 10px;
    }
    .homeTeamCatCardTextContainer .btn {
        display: block;
        width: 100%;
        text-align: center;
    }

}

@media only screen and (max-width: 1000px) {
    #homeTeam {
        padding: 100px 0;
    }
    .homeTeamCatContainer {
        width: 100%;
    }
    .homeTeamFlexWrapper {
        flex-direction: column;
    }
    .homeTeamTextContainer {
        width: 50%;
    } 
}

@media only screen and (max-width: 700px) {
    .homeTeamTextContainer {
        width: 70%;
    } 
}

@media only screen and (max-width: 500px) {
    .homeTeamCatCardContainer {
        width: 100%;
    }  
    .homeTeamTextContainer {
        width: 100%;
    }
}

@media only screen and (max-width: 420px) {
    .homeTeamBtnContainer {
        flex-direction: column;
    }
    .homeTeamText {
        margin-bottom: 20px;
    }
    .homeTeamBtnContainer .btn {
        display: block;
        width: 100%;
        text-align: center;
    }
    .homeTeamBtnContainer>a:first-child {
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .homeTeamHeading {
        font-size: 39px;
    }
    .homeTeamBtnContainer>a:last-child {
        margin-left: 0px;
    }
}

@keyframes pulsing {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}