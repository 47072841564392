#dancelistOverview {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
}

.dancelistOverviewContainer {
    max-width: 1600px;
    width: 1600px;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
}

.dancelistOverviewTextContainer {
    width: 30%;
    position: relative;
}

.dancelistOverviewTextContainer>img {
    position: absolute;
    top: 50%;
    left: 45%;
    width: 850px;
    transform: translate(-50%, -50%);
    opacity: .2;
}

.dancelistOverviewHeading {
    color: var(--secundary);
    font-weight: 500;
    font-size: 39px;
    margin-bottom: 30px;
}

.dancelistOverviewText {
    margin-bottom: 30px;
    color: var(--secundary);
    line-height: 1.3;
}

.dancelistOverviewCtaContainer{
    padding-left: 50px;
    width: 55%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.homeTeamCatCardContainer.dancelist {
    width: calc((100% / 2) - 15px) !important;
    height: 350px !important;
    margin-bottom: 30px;
}

/* .homeTeamCatCardContainer.dancelist:nth-child(2) {
    transform: translateY(-25px);
}

.homeTeamCatCardContainer.dancelist:nth-child(4) {
    transform: translateY(-25px);
}

.homeTeamCatCardContainer.dancelist:nth-child(1) {
    transform: translateY(25px);
}

.homeTeamCatCardContainer.dancelist:nth-child(3) {
    transform: translateY(25px);
} */

.homeTeamCatCardBtn {
    background-color: var(--secundary);
}

.homeTeamCatCardBtn:hover {
    background-color: var(--orangeColor);
    border-color: var(--orangeColor);
    color: var(--secundary);
}

@media only screen and (max-width: 1024px) {
    #dancelistOverview {
        height: fit-content;
        margin-bottom: 100px;
    }
    .dancelistOverviewContainer {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 50px;
    }
    .dancelistOverviewTextContainer {
        width: 55%;
        margin-bottom: 50px;
    }
    .dancelistOverviewCtaContainer {
        width: 100%;
        padding: 0;
    }
    .dancelistOverviewTextContainer>img {
        top: 50%;
        left: 45%;
    }
}

@media only screen and (max-width: 650px) {
    .dancelistOverviewTextContainer {
        width: 100%;
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 550px) {
    .homeTeamCatCardContainer.dancelist {
        width: 100% !important;
    }
    .dancelistOverviewHeading {
        font-size: 32px;
    }
}