:root {
    --primary: #EFEFEF;
    --secundary: rgb(52, 58, 84);
    --secundary50opacity: rgba(52, 58, 84, 0.5);
    --secundary70opacity: #353644;
    --white: #ffffff;
    --orangeColor: #FF7F50;
    --orangeColor50opacity: rgba(255, 127, 80, 0.5);
    --orangeColor70opacity: rgba(255, 127, 80, 0.7);
    --errorColor: #F0706A;
    --succesColor: #65dd6b;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
  }

  .App {
    overflow-x: hidden;
  }
  
  .navContainer {
    width: 100vw;
    background-color: var(--secundary);
  }
  
  .mainContent {
    width: 100vw;
    background-color: var(--primary);
  }

  .headingSpanWord {
    color: var(--orangeColor);
    font-weight: 700;
    display: inline-block;
  }